import React from "react";
import { HashRouter, Switch, Route } from "react-router-dom";
import LandingPage from "./LandingPage";
import MapPage from "./MapPage";

export default function App() {
  return (
    <HashRouter>
      <Switch>
        <Route path="/map/:lat,:lng" component={MapPage} />
        <Route path="/" component={LandingPage} />
      </Switch>
    </HashRouter>
  );
}
