import React from "react";
import { Table } from "react-bootstrap";
export default function LocationsTable(props) {
  const { positions = [], onBtnClick } = props;
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>#</th>
          <th>Label</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {positions.map((position, i) => (
          <tr key={`${position.latitude},${position.longidute}`}>
            <td>{i + 1}</td>
            <td>{position.label}</td>
            <td>
              <button className="btn" onClick={() => onBtnClick(position)}>
                Show
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
