import React from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow
} from "react-google-maps";

const MapWithAMarker = withScriptjs(
  withGoogleMap(props => {
    const {
      positions = [],
      onMapMounted,
      defaultCenter,
      activePosition,
      onIdle,
      getLocationIcon
    } = props;
    console.log(defaultCenter);
    return (
      <GoogleMap
        defaultZoom={15}
        defaultCenter={defaultCenter}
        ref={onMapMounted}
        onIdle={onIdle}
      >
        {positions.map(position => {
          const onClick = props.onMarkerClick.bind(this, position);
          return (
            <Marker
              key={`${position.latitude}${position.longitude}`}
              onClick={onClick}
              position={{ lat: position.latitude, lng: position.longitude }}
              icon={getLocationIcon(position)}
            >
              {activePosition === position && (
                <InfoWindow
                  onCloseClick={() => props.onCloseInfoWindowClick(position)}
                >
                  <div>{position.infoWindowBody}</div>
                </InfoWindow>
              )}
            </Marker>
          );
        })}
      </GoogleMap>
    );
  })
);

export default MapWithAMarker;
