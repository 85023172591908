import React from "react";
import {
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button,
  Card,
  Container,
  Row,
  Col,
  InputGroup
} from "react-bootstrap";

const styles = {
  eventMenu: {
    minWidth: 200,
    cursor: "pointer",
    opacity: 0.4
  },
  "opacity-1": {
    opacity: 1
  },
  eventMenusContaienr: {
    minWidth: 670,
    alignItems: "center",
    justifyContent: "space-between"
  },
  datesContainer: {
    minWidth: 400
  },
  eventType: {}
};

const EventMenu = props => {
  const { event, activeEventCategoryIds, toggleEventCategory } = props;
  let computedStyles = { ...styles.eventMenu };
  if (activeEventCategoryIds) {
    const isActive = activeEventCategoryIds.indexOf(event.id) >= 0;
    if (isActive) {
      computedStyles = {
        ...computedStyles,
        ...styles["opacity-1"]
      };
    }
  }
  return (
    <div
      className="d-flex flex-column align-items-center justify-content-center btn pt-2 m-2"
      style={computedStyles}
      onClick={() => toggleEventCategory(event)}
    >
      <img src={event.icon} />
      {event.name}
    </div>
  );
};

const EventType = props => {
  const { eventType, activeEventTypes, toggleEventType } = props;
  let computedStyles = { ...styles.eventType };
  const isActive = activeEventTypes.indexOf(eventType) >= 0;
  if (isActive) {
    computedStyles = {
      ...computedStyles
    };
  }
  return (
    <NavDropdown.Item
      onSelect={() => toggleEventType(eventType)}
      style={computedStyles}
      active={isActive}
    >
      {eventType}
    </NavDropdown.Item>
  );
};

// const events = [
//   {
//     icon:
//       "https://res.cloudinary.com/dljareqvp/image/upload/c_limit,h_48,w_47/v1511467891/church_wntdmf.png",
//     title: "Parishes"
//   },
//   { icon: "", title: "Festivals" },
//   { icon: "", title: "Fundraisers" },
//   { icon: "", title: "Social Events" },
//   { icon: "", title: "Spiritual Events" },
//   { icon: "", title: "Support Groups" },
//   { icon: "", title: "Advocacy Events" },
//   { icon: "", title: "Local Businesses" },
//   { icon: "", title: "Volunteer Opportunities" }
// ];
// const eventTypes = [
//   "Family",
//   "Teen",
//   "Senior",
//   "Women",
//   "YA",
//   "Adults",
//   "Men",
//   "Young Adults",
//   "Adult",
//   "Young Adult"
// ];
export default function Filter(props) {
  const {
    toggleTable,
    isTableVisible,
    eventCategories = [],
    activeEventCategoryIds = [],
    toggleEventCategory,

    eventTypes = [],
    activeEventTypes = [],
    toggleEventType,

    dateRange,
    onDateRangeChange
  } = props;
  return (
    <Navbar bg="light" expand="lg">
      <Navbar.Brand href="#home">Filter</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          {/* <Nav.Link href="#home">Home</Nav.Link>
          <Nav.Link href="#link">Link</Nav.Link>
          <NavDropdown title="Dropdown" id="basic-nav-dropdown">
            <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
            <NavDropdown.Item href="#action/3.2">
              Another action
            </NavDropdown.Item>
            <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="#action/3.4">
              Separated link
            </NavDropdown.Item>
          </NavDropdown> */}
          <NavDropdown title="Events" id="events-dropdown">
            <div
              className="d-flex flex-wrap p-2 "
              style={styles.eventMenusContaienr}
            >
              {eventCategories.map(eventCategory => (
                <EventMenu
                  key={eventCategory.id}
                  event={eventCategory}
                  toggleEventCategory={toggleEventCategory}
                  activeEventCategoryIds={activeEventCategoryIds}
                />
              ))}
            </div>
          </NavDropdown>
          <NavDropdown title="Dates" id="dates-dropdown">
            <div
              className="d-flex align-items-center p-2"
              style={styles.datesContainer}
            >
              <InputGroup>
                <FormControl
                  type="date"
                  value={dateRange && dateRange[0] ? dateRange[0] : ""}
                  onChange={e => onDateRangeChange({ 0: e.target.value })}
                />
                <FormControl
                  type="date"
                  value={dateRange && dateRange[1] ? dateRange[1] : ""}
                  onChange={e => onDateRangeChange({ 1: e.target.value })}
                />
                {(dateRange[0] || dateRange[1]) && (
                  <InputGroup.Append>
                    <Button
                      variant="secondary"
                      onClick={() => onDateRangeChange({ 0: null, 1: null })}
                    >
                      ×
                    </Button>
                  </InputGroup.Append>
                )}
              </InputGroup>
            </div>
          </NavDropdown>
          <NavDropdown title="Event Type" id="eventtypes-dropdown">
            {eventTypes.map(eventType => (
              <EventType
                key={eventType}
                eventType={eventType}
                activeEventTypes={activeEventTypes}
                toggleEventType={toggleEventType}
              />
            ))}
          </NavDropdown>
        </Nav>
        {/* <Form inline>
          <FormControl type="text" placeholder="Search" className="mr-sm-2" />
          <Button variant="outline-success">Search</Button>
        </Form> */}
        <Button onClick={toggleTable}>
          {isTableVisible ? "Hide" : "Show"} Table
        </Button>
      </Navbar.Collapse>
    </Navbar>
  );
}
