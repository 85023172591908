import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Map from "../Map";
import config from "../../config";
import LocationsTable from "../LocationsTable";
import Filter from "../Filter";
import moment from "moment";

const styles = {
  fillHeight: {
    height: "100vh"
  },
  locationsTable: {
    maxHeight: "calc(100vh - 56px)",
    overflow: "auto"
  }
};
// const positions = [
//   {
//     label: "Position A",
//     lat: -34.397,
//     lng: 150.644,
//     infoWindowBody: "Hello world!"
//   },
//   {
//     label: "Position B",
//     lat: -35.317,
//     lng: 150.614,
//     infoWindowBody: "Hello world!"
//   }
// ];
class MapPage extends Component {
  state = {
    mapReady: false,
    positions: [],
    defaultCenter: null,
    activePosition: null,
    isTableVisible: true,

    eventCategories: [],
    activeEventCategoryIds: [],

    eventTypes: [],
    activeEventTypes: [],

    dateRange: [null, null],

    defaultCenter: null
  };
  mapRef = React.createRef();
  componentDidMount() {
    const { lat, lng } = this.props.match.params;
    this.setState(
      {
        defaultCenter: { lat: parseFloat(lat), lng: parseFloat(lng) }
      },
      () => {
        // fetch categories
        this.initEventCategories();

        this.initEventTypes();

        this.initPositions();
      }
    );
  }

  initPositions = () => {
    fetch("http://houstoncatholicmap.com/new_site/api.php?locations")
      .then(response => response.json())
      .then(response => {
        if (Array.isArray(response.data) && response.data.length > 0) {
          this.setState({
            positions: response.data.map(p => {
              p.latitude = parseFloat(p.latitude);
              p.longitude = parseFloat(p.longitude);
              return p;
            })
          });
        }
      });
  };

  initEventCategories = () => {
    fetch("http://houstoncatholicmap.com/new_site/api.php?categories")
      .then(response => response.json())
      .then(response => {
        if (response.status === 200) {
          if (Array.isArray(response.data) && response.data.length > 0) {
            this.setState({
              eventCategories: response.data,
              activeEventCategoryIds: response.data.map(c => c.id)
            });
          }
        }
      });
  };

  initEventTypes = () => {
    // http://houstoncatholicmap.com/new_site/api.php?event_types
    fetch("http://houstoncatholicmap.com/new_site/api.php?event_types")
      .then(response => response.json())
      .then(response => {
        if (response.status === 200) {
          if (Array.isArray(response.data) && response.data.length > 0) {
            this.setState({
              eventTypes: response.data,
              activeEventTypes: response.data.slice()
            });
          }
        }
      });
  };

  getVisiblePositions = () => {
    if (!this.state.mapReady || this.state.positions.length === 0) return [];
    return this.filteredPositions().filter(p => {
      if (!this.mapRef.getBounds()) return false;
      return this.mapRef.getBounds().contains({
        lat: p.latitude,
        lng: p.longitude
      });
    });
  };

  toggleTable = () => {
    this.setState({ isTableVisible: !this.state.isTableVisible });
  };
  // handlers

  handleMapMounted = mapRef => {
    this.mapRef = mapRef;
  };

  handleMapIdle = () => {
    this.setState({
      mapReady: true
    });
  };

  activatePosition = position => {
    this.setState({
      activePosition: position,
      defaultCenter: { lat: position.latitude, lng: position.longitude }
    });
  };
  deactivatePosition = () => {
    this.setState({
      activePosition: null
    });
  };

  toggleEventCategory = eventCategory => {
    const i = this.state.activeEventCategoryIds.indexOf(eventCategory.id);
    const activeEventCategoryIds = this.state.activeEventCategoryIds.slice();
    if (i === -1) {
      activeEventCategoryIds.push(eventCategory.id);
    } else {
      activeEventCategoryIds.splice(i, 1);
    }
    this.setState({
      activeEventCategoryIds
    });
  };
  toggleEventType = eventType => {
    const i = this.state.activeEventTypes.indexOf(eventType);
    const activeEventTypes = this.state.activeEventTypes.slice();
    if (i === -1) {
      activeEventTypes.push(eventType);
    } else {
      activeEventTypes.splice(i, 1);
    }
    this.setState({
      activeEventTypes: activeEventTypes
    });
  };

  handleDateRangeChange = dateRange => {
    const _dateRange = this.state.dateRange.slice();
    for (let key in dateRange) {
      _dateRange[parseInt(key)] = dateRange[key];
    }

    const startDate = moment(_dateRange[0]);
    const endDate = moment(_dateRange[1]);

    if (startDate.isValid() && endDate.isValid()) {
      if (startDate.isAfter(endDate)) {
        _dateRange[0] = _dateRange[1];
      }
    }

    this.setState({
      dateRange: _dateRange
    });
  };

  getLocationIcon = location => {
    return this.state.eventCategories.find(c => location.category_id === c.id)
      .icon;
  };

  filteredPositions = () => {
    return this.state.positions.filter(position => {
      const {
        activeEventCategoryIds,
        activeEventTypes,
        dateRange
      } = this.state;
      const { category_id, event_type, date } = position;

      const hasCategoryActive =
        Array.isArray(activeEventCategoryIds) &&
        activeEventCategoryIds.indexOf(category_id) >= 0;

      const hasEventTypeActive =
        Array.isArray(activeEventTypes) &&
        activeEventTypes.indexOf(event_type) >= 0;

      let isInActiveDateRange = true;
      const dateFormat = "DD-MM-YYYY";

      const startDate = moment(new Date(dateRange[0])).startOf("day");
      const endDate = moment(new Date(dateRange[1])).startOf("day");
      const positionDate = moment(date, dateFormat).startOf("day");

      const isStartDateValid = dateRange[0] && startDate.isValid();
      const isEndDateValid = dateRange[1] && endDate.isValid();

      if (isStartDateValid && isEndDateValid && positionDate.isValid()) {
        isInActiveDateRange =
          positionDate.isSameOrAfter(startDate) &&
          positionDate.isSameOrBefore(endDate);
      } else if (
        isStartDateValid &&
        isEndDateValid &&
        !positionDate.isValid()
      ) {
        isInActiveDateRange = false;
      }
      return hasCategoryActive && hasEventTypeActive && isInActiveDateRange;
    });
  };

  render() {
    const _positions = this.filteredPositions();
    const {
      defaultCenter: _defaultCenter,
      // positions: _positions,
      activePosition: _activePosition,
      isTableVisible: _isTableVisible,
      eventCategories: _eventCategories,
      activeEventCategoryIds: _activeEventCategoryIds,
      eventTypes: _eventTypes,
      activeEventTypes: _activeEventTypes,
      dateRange: _dateRange
    } = this.state;
    return (
      <Container fluid style={styles.fillHeight} className="d-flex flex-column">
        <Filter
          activeEventCategoryIds={_activeEventCategoryIds}
          eventCategories={_eventCategories}
          toggleEventCategory={this.toggleEventCategory}
          activeEventTypes={_activeEventTypes}
          eventTypes={_eventTypes}
          toggleEventType={this.toggleEventType}
          isTableVisible={_isTableVisible}
          toggleTable={this.toggleTable}
          dateRange={_dateRange}
          onDateRangeChange={this.handleDateRangeChange}
        />

        {_defaultCenter && (
          <Row className="flex-grow-1">
            <Col
              md={_isTableVisible ? 7 : 12}
              style={styles.mapCol}
              className="pl-0 pr-0"
            >
              <Map
                googleMapURL={config.googleMapURL}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `100%` }} />}
                mapElement={<div style={{ height: `100%` }} />}
                // custom props
                defaultCenter={_defaultCenter}
                positions={_positions}
                activePosition={_activePosition}
                // custom functions
                getLocationIcon={this.getLocationIcon}
                onMapMounted={this.handleMapMounted}
                onMarkerClick={this.activatePosition}
                onCloseInfoWindowClick={this.deactivatePosition}
                onIdle={this.handleMapIdle}
              />
            </Col>
            {_isTableVisible && (
              <Col
                md={5}
                className="pl-0 pr-0 d-flex flex-column"
                style={styles.locationsTable}
              >
                <LocationsTable
                  positions={this.getVisiblePositions()}
                  onBtnClick={this.activatePosition}
                />
              </Col>
            )}
          </Row>
        )}
      </Container>
    );
  }
}

export default MapPage;
