import React, { Component } from "react";

const styles = {
  listGroup: {
    maxHeight: "100%",
    overflow: "auto"
  },
  listItem: {
    cursor: "pointer"
  },
  textShadow: {
    textShadow: "1px 1px #000"
  }
};
class LandingPage extends Component {
  state = { places: [], searchTerm: "" };

  componentDidMount() {
    document.body.style.background =
      "linear-gradient(to left, #005aa7, #fffde4)";
  }
  componentWillUnmount() {
    document.body.style.background = "";
  }

  handlePlaceSearch = e => {
    this.setState(
      {
        searchTerm: e.target.value
      },
      () => {
        const placesMatch = [];
        fetch(
          `http://houstoncatholicmap.com/new_site/api.php?search_address=${this.state.searchTerm}`
        )
          .then(response => response.json())
          .then(response => {
            if (response.status === 200 && Array.isArray(response.data)) {
              for (let place of response.data) {
                if (
                  typeof place.address === "string" &&
                  place.address
                    .toLowerCase()
                    .indexOf(this.state.searchTerm.trim().toLowerCase()) >= 0
                ) {
                  placesMatch.push(place);
                }
              }

              this.setState({
                places: [...new Set(placesMatch)]
              });
            }
          })
          .catch(error => {
            console.error(error);
          });
      }
    );
  };
  handlePlaceClick = place => {
    const { latitude, longitude } = place;
    this.props.history.push(`/map/${latitude},${longitude}`);
  };
  render() {
    return (
      <div>
        <nav className="navbar navbar-light bg-light static-top">
          <div className="container">
            <a className="navbar-brand" href="#">
              Houston Catholic Map
            </a>
            {/* <a className="btn btn-primary" href="#">
              Sign In
            </a> */}
          </div>
        </nav>

        <header className="masthead" style={styles.masthead}>
          <div className="container">
            <div className="row">
              <div className="col-xl-9 mx-auto">
                <h1 className="mb-5 mt-5 text-center text-dark">
                  Search Events
                </h1>
              </div>
              <div className="col-md-10 col-lg-8 col-xl-7 mx-auto">
                <form>
                  <div className="form-row">
                    <div className="col-12">
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        placeholder="Address..."
                        onChange={this.handlePlaceSearch}
                        value={this.searchTerm}
                      />
                    </div>

                    {this.state.searchTerm.length > 0 && (
                      <div className="col-12">
                        <ul className="list-group">
                          {this.state.places.map(place => (
                            <li
                              key={`${place.latitude},${place.longitude}`}
                              className="list-group-item"
                              style={styles.listItem}
                              onClick={() => this.handlePlaceClick(place)}
                            >
                              {place.address}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </header>
      </div>
    );
  }
}

export default LandingPage;
